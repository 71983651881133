<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <cc-icon v-if="isCustomIcon(item.icon)" :icon="item.icon" />

      <feather-icon
        :icon="item.icon || 'CircleIcon'"
        :badge="item.badge"
        :badgeClasses="item.badgeClasses"
        size="21"
        v-else
      />
      <span class="menu-title text-truncate ml-1">{{ item.title }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import CcIcon from '@/@core/components/cc-icon/CcIcon.vue'

export default {
  components: {
    BLink,
    BBadge,
    CcIcon,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const isCustomIcon = (item) => item && item.includes('Custom')

    return {
      isActive,
      linkProps,
      isCustomIcon,
      updateIsActive,
      canViewVerticalNavMenuLink,
    }
  },
}
</script>
