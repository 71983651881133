<template>
  <layout-vertical>
    <router-view />

    <template #footer>
      <component :is="layoutFooter" />
    </template>
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  components: {
    LayoutVertical,
    AppFooter,
  },
  computed: {
    layoutFooter() {
      return 'app-footer'
    },
  },
}
</script>
