<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"></div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <user-data />
      <nav-bar-divisor />
      <logout-button />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue'
import UserData from './components/UserData.vue'
import LogoutButton from './components/LogoutButton.vue'
import NavBarDivisor from './components/NavBarDivisor.vue'

export default {
  components: {
    BLink,
    UserData,
    BNavbarNav,
    NavBarDivisor,
    LogoutButton,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
